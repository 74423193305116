<template>
    <div>
    <v-dialog persistent width="500" scrollable v-model="display">
        <v-card>
            <v-card-title>{{ title }}</v-card-title>
                <div class="text-center" v-if="loading.data">
                    <v-progress-circular
                        indeterminate>
                    </v-progress-circular>
                </div>
            <v-card-text v-else>
            <v-btn icon>
                <v-icon color="primary" @click='pickFile'>mdi-camera-image</v-icon>
            </v-btn>  
            <v-flex xs12 class="text-xs-center text-sm-center text-md-center text-lg-center mb-1">
            <v-avatar height="100" width="100" rounded>
                <img :src="form.logo" height="150" v-if="form.logo"/>
            </v-avatar>
                <input
                    type="file"
                    style="display: none"
                    ref="image"
                    accept="image/*"
                    @change="onFilePicked">
            </v-flex>
                <v-form v-model="value" ref="form">
                    <v-text-field  :rules="rules.required"
                        dense v-model="form.name" outlined :label="$t('name')" :placeholder="$t('name')"></v-text-field>
                    <v-text-field  :rules="rules.required" dense v-model="form.email" outlined :label="$t('email')" :placeholder="$t('email')"></v-text-field>
                    <v-text-field  :rules="rules.required" dense v-model="form.phone" outlined :label="$t('phone')" :placeholder="$t('phone')"></v-text-field>
                     <v-text-field  :rules="rules.required" dense v-model="form.rate" outlined :label="$t('commission rate')" :placeholder="$t('commission rate')"></v-text-field>
                    <v-text-field  :rules="rules.required" dense v-model="form.address" outlined :label="$t('address')" :placeholder="$t('address')"></v-text-field>
                    <v-text-field  :rules="rules.required" dense v-model="form.description" outlined :label="$t('description')" :placeholder="$t('description')"></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn text :loading="btnLoading" :disabled="!value" @click="submit">{{ $t('save') }}</v-btn>
                <v-btn text @click="close">{{ $t('close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: "CompaynDialog",
    props: ['id'],
    data() {
        return {
            display: true,
            value: false,
            imageName: '',
            imageUrl: '',
            imageFile: '',
            btnLoading: false,
            loading: {
                data: false
            },
            form: {
                logo: null,
                name: null,
                email:null,
                rate: null,
                address: null,
                phone: null,
                description : null
            },
            rules: {
                required: [(v) => !!v || this.$t("rules_required")],
            },
        
        }
    },
    components: {
        
    },
    computed: {
        title() {
            return this.id == null ? this.$t('New Company') : this.$t('Edit Company');
        }
    },
    methods: {
        ...mapActions('Api', ["post", "getAll", "updata"]),
        ...mapActions('snackBar', ["setSnackBars"]),
        getData() {
            try {
                this.loading.data = true;
                this.getAll(`/companies/${this.id}`).then((res) => {
                    console.log(res.data.data);
                    this.form = res.data.data;
                    this.loading.data = false;
                }).catch(() => {
                    this.loading.data = false;
                });
            } catch {
                console.log('error');
            }
        },
        submit() {
            this.btnLoading = true;
            if(this.id == null) {
                this.save();
            } else {
                this.edit();
            }
            
        },
        edit() {
            let data = new FormData();
            data.append('logo', this.imageFile);
            data.append('name_ar', this.form.name); 
            data.append('name_en', this.form.name); 
            data.append('email', this.form.email); 
            data.append('phone', this.form.phone); 
            data.append('address', this.form.address); 
            data.append('description', this.form.description); 
            data.append('person_first_name', this.form.name); 
            data.append('person_gender', 'Male'); 
            data.append('person_second_name', this.form.name); 
            data.append('rate', this.form.rate); 
            data.append('user_email', this.form.email); 
            data.append('user_mobile', this.form.phone); 
            data.append('user_password', '12345678'); 
            data.append('user_password_confirmation', '12345678'); 
            data.append('username', this.form.name); 
            
            this.updata({ url: `/companies/${this.id}`, form: data}).then((res) => {
                this.btnLoading = false;
                 this.setSnackBars({color: "success", message: this.$t("record_save_success")});
                 this.$emit('close');
                console.log(res);
            }).catch((error) => {
                this.btnLoading = false;
                let message = error.response ? error.response.data.message : error.message;
                this.setSnackBars({color: "error", message: message});
            })

        },
        save() {
            let data = new FormData();
            data.append('logo', this.imageFile);
            data.append('name_ar', this.form.name); 
            data.append('name_en', this.form.name); 
            data.append('email', this.form.email); 
            data.append('phone', this.form.phone); 
            data.append('address', this.form.address); 
            data.append('description', this.form.description); 
            data.append('person_first_name', this.form.name); 
            data.append('person_gender', 'Male'); 
            data.append('person_second_name', this.form.name); 
            data.append('rate', 1.0); 
            data.append('user_email', this.form.email); 
            data.append('user_mobile', this.form.phone); 
            data.append('user_password', '12345678'); 
            data.append('user_password_confirmation', '12345678'); 
            data.append('username', this.form.name); 
            
            this.post({ url: "/companies", form: data}).then((res) => {
                this.btnLoading = false;
                 this.setSnackBars({color: "success", message: this.$t("record_save_success")});
                 this.$emit('close');
                console.log(res);
            }).catch((error) => {
               // console.log('tag', [error.response.data.errors]);
                this.btnLoading = false;
                // let message = error.response ? error.response.data.message : error.message;
                [error.response.data.errors].forEach((e) => {
                    this.setSnackBars({color: "error", message: e});
                })
            })
        },
        close() {
           this.$emit('close'); 
        },
         pickFile() {
      this.$refs.image.click()
    },

    onFilePicked(e) {
      const files = e.target.files
      if (files[0] !== undefined) {
        this.imageName = files[0].name
        if (this.imageName.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.form.logo = fr.result
          this.imageFile = files[0] // this is an image file that can be sent to server...
        })
      } else {
        this.imageName = ''
        this.imageFile = ''
        this.imageUrl = ''
      }
    }
    },
    created() {
        if(this.id != null) this.getData();
    }

}
</script>

<style>

</style>