<template>
    <div>
        <v-card flat>
            <v-card-title>
                {{ $t('companies') }}
            <v-spacer></v-spacer>
             <v-tooltip v-if="seleted.length" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            depressed
                                            class="ma-2 white--text"
                                             @click="ShowCompany(seleted[0].id)"
                                            v-bind="attrs"
                                            v-on="on">
                                            {{ $t('View User') }}
                                            <v-icon
                                                right
                                                dark>
                                                    mdi-eye
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('View User') }}</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="green"
                                            depressed
                                            class="ma-2 white--text"
                                            v-bind="attrs"
                                            @click="addCompany"
                                            v-on="on">
                                            {{ $t('Add New User') }}
                                            <v-icon
                                                right
                                                dark>
                                                    mdi-plus
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('Add New User') }}</span>
                                </v-tooltip>
                                <v-tooltip  v-if="seleted.length" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="blue"
                                            class="ma-2 white--text"
                                            depressed
                                            v-bind="attrs"
                                            @click="editCompany(seleted[0].id)"
                                            v-on="on">
                                            {{ $t('Update User') }}
                                            <v-icon
                                                right
                                                dark>
                                                    mdi-pen
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('Update User') }}</span>
                                </v-tooltip>
                                <v-tooltip  v-if="seleted.length" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="red"
                                            class="ma-2 white--text"
                                            depressed
                                            v-bind="attrs"
                                            v-on="on">
                                            {{ $t('Delete User') }}
                                            <v-icon
                                                right
                                                dark>
                                                    mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('Delete User') }}</span>
                                </v-tooltip>
            </v-card-title>
            <v-card-text> 
                <v-data-table
                    show-select
                    single-select
                    v-model="seleted"
                    :loading="loading"
                    :headers="header"
                    :items="companies"
                    dense>
                </v-data-table>             
            </v-card-text>
        </v-card>
        <compayn-dialog :id="company.id" v-if="company.show" @close="company.show = false"/>
        <show-company :id="showCompany.id" v-if="showCompany.show" @close="showCompany.show = false"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CompaynDialog from '../../components/companies/companyDailog.vue';
import ShowCompany from './ShowCompany.vue';
export default {
    name: 'Companies',
    components: {
       CompaynDialog,
        ShowCompany,
        
    },
    data() {
        return {
            seleted: [],
            loading: false,
            companies: [],
            company: {
                show: false,
                id: null,
            },
            showCompany: {
                show: false,
                id: null
            }
           
        }
    },
    computed: {
        ...mapState(['user']),
         auth() {
     return this.user != null ? this.user.type == 'Super-Admin' ?  true : false : false;
    },
        header() {
            return [
                {text: this.$t('name'), value: 'name', },
                {text: this.$t('mobile'), value: 'phone', },
                {text: this.$t('Email'), value: 'email', },
                {text: this.$t('address'), value: 'address', },
                {text: this.$t('description'), value: 'description', },
                // {text: this.$t('rate'), value: 'rate', }
            ]
        }
      
    },
    methods: {
        ...mapActions('Api', ["getAll"]),
        getcompanies(v) {
            this.companies  = [];
            this.loading = true;
           v = this.auth ? '/companies' : `/companies/policies`;
            this.getAll(`${v}`).then((res) => {
                console.log(res.data.data);
                this.companies = res.data.data;
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })

        },
        clickTab(v) {
            this.getcompanies(v);
        },
        addCompany() {
            this.company.show = true;
        },
        editCompany(id) {
            this.company.id = id;
            this.company.show = true;
        },
        ShowCompany(id) {
            this.showCompany.id = id;
            this.showCompany.show = true;
        }
       
    },
    created() {
        this.getcompanies();
    }
}
</script>

<style>

</style>