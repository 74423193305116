var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('companies'))+" "),_c('v-spacer'),(_vm.seleted.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.ShowCompany(_vm.seleted[0].id)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('View User'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,false,1147860324)},[_c('span',[_vm._v(_vm._s(_vm.$t('View User')))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"green","depressed":""},on:{"click":_vm.addCompany}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Add New User'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Add New User')))])]),(_vm.seleted.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"blue","depressed":""},on:{"click":function($event){return _vm.editCompany(_vm.seleted[0].id)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Update User'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-pen ")])],1)]}}],null,false,4058754327)},[_c('span',[_vm._v(_vm._s(_vm.$t('Update User')))])]):_vm._e(),(_vm.seleted.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"red","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Delete User'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,false,2938237975)},[_c('span',[_vm._v(_vm._s(_vm.$t('Delete User')))])]):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"show-select":"","single-select":"","loading":_vm.loading,"headers":_vm.header,"items":_vm.companies,"dense":""},model:{value:(_vm.seleted),callback:function ($$v) {_vm.seleted=$$v},expression:"seleted"}})],1)],1),(_vm.company.show)?_c('compayn-dialog',{attrs:{"id":_vm.company.id},on:{"close":function($event){_vm.company.show = false}}}):_vm._e(),(_vm.showCompany.show)?_c('show-company',{attrs:{"id":_vm.showCompany.id},on:{"close":function($event){_vm.showCompany.show = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }