<template>
  <div>
    <v-dialog persistent width="950" v-model="display">
      <v-card>
        <v-card-title
          >{{ $t("show_company") }}
          <v-spacer></v-spacer>
        </v-card-title>
        <div class="text-center" v-if="loading.data">
          <v-progress-circular indeterminate> </v-progress-circular>
        </div>
        <v-card-text v-else>
          <v-simple-table>
            <tbody>
              <tr>
                <th>{{ $t("company_name") }}</th>
                <td>{{ company.name }}</td>
                <th>{{ $t("email") }}</th>
                <td>{{ company.email }}</td>
              </tr>
              <tr>
                <th>{{ $t("phone") }}</th>
                <td>{{ company.phone }}</td>
                <th>{{ $t("address") }}</th>
                <td>{{ company.address }}</td>
              </tr>
              <tr>
                <th>{{ $t("description") }}</th>
                <td>{{ company.description }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-tabs v-model="tab">
            <v-tab>{{ $t("schemes") }}</v-tab>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-data-table
                  dense
                  hide-default-footer
                  disable-pagination
                  show-select
                  single-select
                  :items="schemes"
                  :headers="headers.schemes"
                >
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="close">{{ $t("close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <scheme-dialog
      v-if="scheme.show"
      :id="scheme.id"
      :companyId="scheme.companyId"
      @close="scheme.show = false"
    />
   
  </div>
</template>

<script>
import { mapActions } from "vuex";

import schemeDialog from "../../components/companies/schemeDialog.vue";
export default {
  components: { schemeDialog },
  name: "ShowCompany",
  props: ["id"],
  data() {
    return {
      display: true,
      company: {},
      tab: null,
      loading: {
        data: false,
      },
      schemes: [],
      scheme: {
        show: false,
        companyId: null,
        id: null,
      },
      ageRang: {
        show: false,
        companyId: null,
        price: null,
        id: null,
      },
      headers: {
        schemes: [
          { text: this.$t("name"), sortable: false, value: "name" },
          { text: this.$t("price"), sortable: false, value: "price" },
          { text: this.$t("coverage"), sortable: false, value: "coverage" },
          { text: this.$t("network"), sortable: false, value: "network" },
          {
            text: this.$t("description"),
            sortable: false,
            value: "description",
          },
        ],
      },
    };
  },

  methods: {
    ...mapActions("Api", ["getAll"]),
    async getData() {
      try {
        this.loading.data = true;
        this.getAll(`/companies/${this.id}`)
          .then((res) => {
            console.log(res.data.data);
            this.company = res.data.data;
            this.loading.data = false;
          })
          .catch(() => {
            this.loading.data = false;
          });
        await this.getSchemel();
      } catch {
        console.log("error");
      }
    },
    getSchemel() {
      this.getAll(`/companies/${this.id}/schemes`)
        .then((res) => {
          console.log(res.data.data);
          this.schemes = res.data.data;
          this.loading.data = false;
        })
        .catch(() => {
          this.loading.data = false;
        });
    },
    openScheme() {
      this.scheme.show = true;
      this.scheme.companyId = this.id;
      // this.scheme.id =
    },
    close() {
      this.$emit("close");
    },
  },
  created() {
    if (this.id != null) this.getData();
  },
};
</script>

<style>
</style>